import React from 'react';
import './App.css';
import Lottie from 'lottie-react-web';
import animation from './flamenco.json';

export default class LottieControl extends React.Component {

  componentWillMount() {
    document.title = 'AlvaroMtz'
  }
  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
        <Lottie options={{
              animationData: animation}}
              height={400}
              width={400}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}/>
          <h1>
            AlvaroMtz
          </h1>
          <p>Web Developer</p>
        </header>
      </div>
    );
  
  }
}
